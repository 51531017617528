import React from "react";

import { pageLinks } from "../data/pageLinks";

import Image from "../components/v2/Image";
import Hero from "../components/v2/Hero";
import Layout from "../components/v2/Layout";
import ContentBlock from "../components/v2/ContentBlock";
import PageSection from "../components/v2/PageSection";
import ImageGrid from "../components/v2/ImageGrid";
import ImageScroller from "../components/v2/ui-blocks/ImageScroller";
import Checkerboard from "../components/v2/CheckerBoard";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

import hero_img from "../images/8. Messaging Platform/Messaging Platform Header – HT – 1920x1200.jpg";

import the_construct_fullWidth from "../images/8. Messaging Platform/Messaging Platform – The Construct – 1920x680.jpg";

import photography_fullWidth from "../images/8. Messaging Platform/Messaging Platform – Photography – 1920x530.jpg";

import messaging_platform_question_formats_1 from "../images/8. Messaging Platform/Messaging Platform – Question Formats – 1280px.jpg";
import messaging_platform_question_formats_2 from "../images/8. Messaging Platform/messaging-platform-question-formats-2-1280x434.jpg";

import Messaging_Platform_Question_Format_Lozenge_svg from "../images/8. Messaging Platform/Asset 1@300x.png";
import Messaging_Platform_Question_Format_Lozenge_Center_svg from "../images/8. Messaging Platform/Asset 12@300x.png";
import Messaging_Platform_Question_Format_Lozenge_Left_svg from "../images/8. Messaging Platform/Asset 14@300x.png";

import Messaging_Platform_Question_Format_CTA_Search_Field_svg from "../images/8. Messaging Platform/Messaging Platform Copy – 800px.svg";
import Messaging_Platform_Question_Format_Digital_Button_svg from "../images/8. Messaging Platform/Asset 11@300x.png";

import TOY7024_V01_384x592 from "../images/8. Messaging Platform/TOY7024_V01_384x592.jpg";
import messaging_platform_cta_2 from "../images/8. Messaging Platform/messaging-platform-cta-2-_x_.jpg";

import messaging_platform_callout_1 from "../images/8. Messaging Platform/Messaging CTA Callout Graphics 1 – 300x600px.png";
import messaging_platform_callout_2 from "../images/8. Messaging Platform/Messaging CTA Callout Graphics 2 – 300x600px.png";

import messaging_platform_driveaway_value from "../images/8. Messaging Platform/messaging-platform-driveaway-value-2560x400.jpg";
import ImageBlock from "../components/v2/ImageBlock";

const HeyToyota = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero image={hero_img} />
      <PageSection sectionId="overview">
        <ContentBlock whiteBg={true}>
          <h2>HEY TOYOTA</h2>
          <div className="text">
            <p>
              The following provides guidelines for the <em>Let’s Go Places</em> messaging platform called Hey Toyota. This platform covers all product, services and offer-based communications from Toyota New Zealand to consumers that fall outside the scope of bespoke product campaigns.
            </p>
            <p>
              The creative construct does not apply to individual store marketing where the voice, or person speaking, is not that of Toyota New Zealand.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="approach">
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <h2>The approach</h2>
          <div className="text">
            <p>
              ‘Hey, Toyota’ represents more than an advertising platform. It’s a
              connection idea. An approach that will help Toyota engage with
              customers at every stage of the journey.
            </p>
            <p>It's about our brand being unexpectedly responsive.</p>
            <p>
              And it’s how Toyota New Zealand will stand out from the crowd and
              set a new standard for people-centric comms, and experiences
              people actually want to engage with.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="construct">
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <h2>The Construct</h2>
          <div className="text">
            <p>
              The Hey Toyota construct is founded on a
              question-and-response style interaction between characters in the
              real world and Toyota New Zealand.
            </p>
            <p>
              In TV this comes to life as a high energy conversation, jumping
              between the real world where our customers are and a white studio
              environment where vehicles and graphics are based.
            </p>
            <p>
              This format is reflected in other channels as well. In print and
              digital executions, headlines can take the form of a question and
              answer, or just an answer that infers a question has been asked.
            </p>
          </div>
        </ContentBlock>
        <Image width="full" src={the_construct_fullWidth}></Image>
      </PageSection>
      <PageSection sectionId="hey-toyota-photography">
        <ContentBlock whiteBg={true}>
          <h2>Hey Toyota photography</h2>
          <div className="text">
            <p>
              While the overarching <em>Let’s Go Places</em> photography
              principles apply to this layer, there are two specific photography
              components in Hey Toyota - people and the product.
            </p>
            <h3>People</h3>
            <p>
              Talent will be shot on location, as if we found them where they
              are. Natural settings, like a high street, in front of a school,
              in a garden, standing on a remote beach, in a field, etc.
            </p>
            <p>
              We need to capture people looking straight down the barrel of the
              camera, and always looking like they’re mid-question - with a
              questioning expression, mouth open as if they’re speaking, or head
              cocked.
            </p>
            <p>
              It’s important that these feel like active engagements with
              Toyota, rather than smiling portraits.
            </p>
            <h3>Product</h3>
            <p>
              Product photography in Hey Toyota follows the same
              guidelines and principles outlined for the overarching brand.
              Vehicles are set against a white studio backdrop. Cars can be
              showcased on their own, with props (if necessary) or as part of a
              range. In instances where a vehicle range is shown, the angles and
              lighting should match so that they feel like they are in the same
              space.
            </p>
          </div>
        </ContentBlock>
        {/* TODO Include sample imagery of vehicles in white studio setting. */}
        <ImageGrid fullWidth={true}>
          <Image width="full" src={photography_fullWidth}></Image>
        </ImageGrid>
        <ContentBlock whiteBg={true}>
          <h2>Using existing imagery</h2>
          <div className="text">
            <p>
              We will often need to use existing imagery of models within our
              graphic environment.
            </p>
            <p>
              The aim is to always make these images feel as though they are
              within the 3D space.
            </p>
            <p>
              This can be achieved by using the ‘horizon’ background, adjusting
              scale to create a sense of realistic perspective, and adjusting
              lighting so it matches across the range.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="hey-toyota-copy">
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <h2>Hey Toyota copy</h2>
          <div className="text">
            <p>
              How we speak is friendly, conversational and energetic. We answer
              honestly. To questions that feel natural and authentic.
            </p>
            <p>
              Questions represent our customers and should never feel forced.
              Rather they should reflect real insights and natural language.
            </p>
            <p>Likewise, our answers should be helpful and amiable.</p>
            <p>
              We don’t get caught up in jargon or corporate speak - we explain
              it.
            </p>
            <p> Succinctly.</p>
            <p>
              This is about putting our responsiveness and customer-centric
              approach on display.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="question-formats">
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <h2>Question Formats</h2>
          <div className="text">
            <p>
              Under Hey Toyota there are two approved headline
              formats. Either ‘Question & Answer’ or ‘Answer Only’.
            </p>
            <p>
              The former should consist of a question that feels authentically
              posed from the consumer’s point of view, followed by our response,
              which is to-the-point and feels conversational. The ‘Answer Only’
              format should also feel conversational but imply a sense of the
              question we are answering.
            </p>
            <p>
              There’s no need for jargon, unless that’s the question we’re
              seeking to clarify.
            </p>
            <p>
              There are two layout options for the ‘Question & Answer’
              construct. Either with talent or without. In either case,
              questions sit within a black text bubble, that is fitted to the
              type and can either appear over imagery of talent (when used) or
              alongside the answer.
            </p>
            <p>
              Responses appear alongside the vehicle in the white studio space
              and do not require an outline.
            </p>
            <p>
              A third option using full-bleed imagery is also available, but is
              reserved for the exclusive right of TNZ marketing and is used
              primarily to promote more generic topics and the ‘platform’
              itself.
            </p>
          </div>
        </ContentBlock>
        <ImageBlock>
          <Image src={messaging_platform_question_formats_1} width={'full'} />
        </ImageBlock>
        {/* <ImageScroller
          vertPadding={true}
          style={{ backgroundColor: "#fafafa" }}
          images={[
            {
              image: {
                svg: messaging_platform_question_formats_1,
                style: { height: "auto", width: "70vw" }
              },
              caption: {
                copy: "Option 1: Question & Answer format"
              }
            },
            {
              image: {
                svg: messaging_platform_question_formats_2,
                style: { height: "auto", width: "70vw" }
              },
              caption: {
                copy: "Option 2: Answer Only format"
              }
            }
          ]}></ImageScroller> */}
        <ContentBlock whiteBg={true}>
          <div className="text">
            <h3>Question Formats – Lozenge</h3>
            <p>Questions are written in Toyota Type Semibold.</p>
            <p>
              The question portion of a headline sits within a text bubble, or
              lozenge device. The height and width of the lozenge is relative to
              the size of the text and can be calculated by the text cap height.
            </p>
            <p>
              The total lozenge height is 1X from the text cap height and 1X
              from the text baseline. The total width of the lozenge is 1.5X
              from the left and right edge of text.
            </p>
            <p>
              This is then repeated to create the second text line as shown.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image
            width="full"
            src={Messaging_Platform_Question_Format_Lozenge_svg}></Image>
          <Image
            width="half"
            src={Messaging_Platform_Question_Format_Lozenge_Center_svg}></Image>
          <Image
            width="half"
            src={Messaging_Platform_Question_Format_Lozenge_Left_svg}></Image>
        </ImageGrid>

        <ContentBlock whiteBg={true}>
          <div className="text">
            <h3>Answer Formats</h3>
            <p>
              Answers are written in Toyota Type Semibold in the same pt. size
              as the question.
            </p>
            <p>
              The answer portion of a headline always sits within the Toyota
              graphic environment.
            </p>
            <p>
              There is flexibility within the position of the answer, however
              its placement should take into account that it needs to be read by
              the viewer as a response.
            </p>
            {/* TODO Show examples of Q&A and Answer-only structures */}
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="call-out-graphics">
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <h2>CTA / call out graphics</h2>
          <div className="text">
            <p>
              Our standard CTA is inspired by a search field. This visual device
              allows us to prompt user behaviour without the need of a direct
              URL. We use this device to call out individual model names or
              products.
            </p>
          </div>
        </ContentBlock>
        <Checkerboard
          colour={Messaging_Platform_Question_Format_CTA_Search_Field_svg}
          title="CTA search field"
          whiteBg={true}
          text={[
            "Text is Toyota Type Regular.",
            "The magnifying glass is 140% of the text cap height.",
            "The space between the magnifying glass and text is 1X.",
            "The search lozenge height is 1X from the text cap height and 1X from the text baseline.",
            "The total width of the lozenge is 1.5X from the left and right edge of text.",
            "Lozenge and copy colour are flexible dependant on execution, but should always adhere to approved brand colours and fonts."
          ]}
        />
        <Checkerboard
          alt={true}
          whiteBg={true}
          colour={Messaging_Platform_Question_Format_Digital_Button_svg}
          title="Digital button"
          text={[
            "In digital environments we can link directly to the desired information, in which case our call-to-action takes the form of a digital button to prompt customers to complete the journey on our website.",
            "The text is Toyota Type Semibold.",
            "The digital button lozenge height is 1X from the text cap height and 1X from the text baseline.",
            "The total width of the lozenge is 1.5X from the left and right edge of text as shown.",
            "Lozenge and copy colour are flexible dependant on execution, but should always adhere to approved brand colours and fonts."
          ]}
        />
        <ImageGrid>
          <Image
            width="half"
            style={{
              maxHeight: "600px",
              width: 'auto',
              padding: '5%'
            }}
            src={messaging_platform_callout_1}></Image>
          <Image
            width="half"
            style={{
              maxHeight: "600px",
              width: 'auto',
              padding: '5%'
            }}
            src={messaging_platform_callout_2}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="toyota-drive-away-price-lockup">
        <ContentBlock whiteBg={true}>
          <h2>Toyota Driveaway Price lockup</h2>
          <div className="text">
            <p>
              To visually showcase the added value included with every Toyota
              vehicle, we have created a standardised ‘inclusions’ lockup for
              use in retail comms.
            </p>
            <p>
              This is useful for Product-and-Price executions but can be applied
              at user’s discretion.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={messaging_platform_driveaway_value}></Image>
        </ImageGrid>
      </PageSection>
      {/* <PageSection sectionId="layout-examples">
        <ContentBlock whiteBg={true}>
          <h2>Layout Examples</h2>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: 'white', paddingTop: '0' }}
          images={[
            {
              image: {
                svg: empty_png,
                style: { height: '570px', width: '570px' }
              },
              caption: {
                copy: 'Lorem ipsum'
              }
            },
            {
              image: {
                svg: empty_png,
                style: { height: '570px', width: '570px' }
              },
              caption: {
                copy: 'Lorem ipsum'
              }
            },
            {
              image: {
                svg: empty_png,
                style: { height: '570px', width: '570px' }
              },
              caption: {
                copy: 'Lorem ipsum'
              }
            }
          ]}></ImageScroller>
      </PageSection> */}
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Photography"
        previousUrl={pageLinks.photography}
        nextText="Next"
        nextHeader="Product Campaigns"
        nextUrl={pageLinks.campaigns}
      />
    </Layout>
  );
};

export default HeyToyota;