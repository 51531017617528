import React from "react";

const ImageBlock = (props) => {
  let classes = "image-block";
  if (props.vertPadding) {
    classes += " vert-padding";
  }
  return <div className={classes}>{props.children}</div>;
};

export default ImageBlock;
